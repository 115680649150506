/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import { Layout } from 'components/common';
import React from 'react';
import { graphql, Link } from 'gatsby';

const BlogPage = ({ data }) => {
  const { nodes } = data.allMdx;
  return (
    <Layout isBlog>
      <ul>
        {nodes.map(post => (
          <article key={post.id}>
            <Link to={post.fields.slug}>
              <h2>{post.frontmatter.title}</h2>
            </Link>
            <small>{post.frontmatter.date}</small>
            <p>{post.excerpt}</p>
            {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
          </article>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { published: { eq: true } } }) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
        }
        fields {
          slug
        }
      }
    }
  }
`;

/* export const query = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
        }
        excerpt
        id
        html
      }
    }
  }
`; */

export default BlogPage;
